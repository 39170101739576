<template>
   <ObiText class="obi-dcm" v-bind="attrs" v-on="$listeners">
      <ObiText class="obi-dcm-content">
         <ObiText class="obi-dcm-image">
            <ObiImage :src="device.getBrand().getLogo()" />
         </ObiText>
         <ObiText class="obi-dcm-name" v-html="device.getName()" />
         <ObiText class="obi-dcm-location" v-html="device.getLocationName()" />
         <ObiText class="d-flex align-items-center mt-2">
            <ObiText class="obi-dcm-status-icon me-2" :class="device.getStatus()" />
            <ObiText v-if="device.getLastSeenAt() !== null"
               >{{ $t('last_seen') }}:
               <timeago :datetime="device.getLastSeenAt()" locale="tr" :auto-update="60"></timeago
            ></ObiText>
            <ObiText v-else>{{ $t('device_never_connected') }}</ObiText>
         </ObiText>
      </ObiText>
      <ObiText v-if="getSubdomain() == 'quedra'">
         <span>
            Lisans Bitiş Tarihi:
            {{ trFormatDate(device.getLicenseEndAt()) }}</span
         >
         <ObiText class="mt-4 text-primary" v-if="isAdmin">
            <ObiTitle>Yeni Lisans Tarihi</ObiTitle>
            <ObiText class="d-flex">
               <ObiInput clean compact class="col-md-4" type="date" v-model="license_end_at" />
               <ObiButton class="ms-2" size="xs" outline wider text="UYGULA" @click="applyLicense" />
            </ObiText>
         </ObiText>
      </ObiText>
      <ObiText @click="openDevicePage" class="obi-dcm-action">
         <ObiImage :src="IconExternalLink" />
      </ObiText>
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';

import Device from '@/api/Models/Device';
import { getSubdomain } from '@/libs/url-utils';
import IconExternalLink from '@/components/Icons/general/IconExternalLink.svg';
import { trFormatDate } from '@/libs/date-utils';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import ErrorHandler from '@/libs/error-handler';
import Notify from '@/libs/notify';
import { mapGetters } from 'vuex';

export default {
   name: 'ObiDeviceCardMini',

   i18n: {
      messages: {
         tr: {
            last_seen: 'Son Görülme',
            device_never_connected: 'Cihaz Hiç Bağlanmadı',
         },
         en: {
            last_seen: 'Last Seen',
            device_never_connected: 'Never Connected',
         },
      },
   },
   props: {
      device: {
         type: Device,
         default: null,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      ...mapGetters({ isAdmin: 'auth/isAdmin' }),
   },
   data() {
      return { IconExternalLink, license_end_at: null };
   },
   methods: {
      openDevicePage() {
         window.open(
            this.$router.resolve({ name: 'devices-show', params: { deviceId: this.device.getId() } }).href,
            '_blank'
         );
      },
      async applyLicense() {
         this.isLoading = true;
         try {
            const response = await PanelDeviceApi.applyLicense(this.device.getId(), {
               license_end_at: this.license_end_at,
            });
            this.device.merge(response.data);
            this.license_end_at = null;
            Notify.success(this.$t('Cihaz lisanslandı'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      getSubdomain,
      trFormatDate,
   },
};
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.obi-dcm {
   padding: 20px;
   display: grid;
   grid-gap: 20px;
   border-radius: 8px;
   align-items: center;
   background-color: #f3f8fe;
   justify-content: space-between;
   grid-template-columns: minmax(120px, max-content) 1fr auto;

   .obi-dcm-image {
      .obi-image {
         width: unset;
         max-width: 90px;
         max-height: 45px;
         object-fit: cover;
         object-position: center;
      }
   }

   .obi-dcm-content {
      display: grid;
   }

   .obi-dcm-name {
      color: $primary;
      font-size: 1.25rem;
      @include text-truncate;
   }

   .obi-dcm-location {
      font-size: 0.8rem;
   }

   .obi-dcm-status-icon {
      width: 12px;
      height: 12px;
      border-radius: 12px;

      &.online {
         background: #31ba3f;
      }

      &.offline {
         background: #ff8e8e;
      }
   }

   .obi-dcm-action {
   }
}
</style>
